import { Location } from '@eo-storefronts/eo-core'
import { Box, CircularProgress } from '@mui/material'
import LocationCard from '~/src/components/marketplace/locations/location-card'
import LocationResponsiveDialog from '~/src/components/marketplace/locations/location-responsive-dialog'
import LocationsEmpty from '~/src/components/marketplace/locations/LocationsEmpty'
import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import { useEoValue } from '~/src/hooks/useEoState'
import {
  MARKETPLACE_IS_FETCHING_LOCATIONS_STATE,
  MARKETPLACE_LOCATIONS_STATE
} from '~/src/stores/marketplace/locations'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const LocationsList = () => {
  const locations = useEoValue<Location[]>(MARKETPLACE_LOCATIONS_STATE)
  const theme = useCustomMuiTheme()
  const isFetchingLocations = useEoValue(MARKETPLACE_IS_FETCHING_LOCATIONS_STATE)

  if (isFetchingLocations) {
    return (
      <Box
        sx={ {
          display: 'flex',
          justifyContent: 'center',
          mt: 20
        } }
      >
        <CircularProgress size={ 40 }/>
      </Box>
    )
  }

  if (!locations?.length && !isFetchingLocations) {
    return <LocationsEmpty/>
  }

  return (
    <>
      <GridList
        columns={ 12 }
        sx={ {
          p: '0 !important',
          [theme.breakpoints.down('md')]: {
            px: `${ theme.spacing(2) } !important`
          }
        } }
      >
        { locations.map((item: Location, index: number) => (
          <GridListItem
            xs={ 12 }
            sm={ 6 }
            md={ 6 }
            lg={ 4 }
            xl={ 4 }
            key={ `${ item.id }-${ index }` }
            sx={ {
              ':hover': {
                cursor: 'pointer'
              }
            } }
          >
            { item.id && (
              <LocationResponsiveDialog
                activator={ <LocationCard
                  location={ item }
                  direction="horizontal"
                  displayOpeningHours
                /> }
                id={ item.id }
              />
            ) }
          </GridListItem>
        )) }
      </GridList>
    </>
  )
}

export default LocationsList
