import { Filter } from '@eo-storefronts/eo-core'
import ListIcon from '@mui/icons-material/List'
import MapIcon from '@mui/icons-material/Map'
import { Box, useMediaQuery } from '@mui/material'
import MarketplaceSubHeader from '~/src/components/layout/sub-header/marketplace-sub-header'
import LocationsSearchField from '~/src/components/marketplace/locations/LocationsSearchField'
import ViewButton from '~/src/components/marketplace/ui/buttons/ViewButton'
import FiltersButton from '~/src/components/marketplace/ui/buttons/filters/FiltersButton'
import PageHeader from '~/src/components/marketplace/utils/PageHeader'
import { buildUrl } from '~/src/helpers/router.helper'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import Params from '~/src/router/enums/params.enum'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { MARKETPLACE_CATEGORIES_BY_UUID_SELECTOR } from '~/src/stores/marketplace/categories'
import { MARKETPLACE_AVAILABLE_FILTERS_STATE } from '~/src/stores/marketplace/filter'
import { MARKETPLACE_SEARCH_BUTTON_STATE } from '~/src/stores/marketplace/search-button'
import LocationsFiltersResponsiveDialog from '~/src/components/marketplace/filter/LocationsFiltersResponsiveDialog'

interface Props {
  categoryUUID: string,
  viewType: string | null,
}

const LocationsHeader = ({ viewType, categoryUUID }: Props) => {
  const { t } = useTranslations()
  const category = useEoValue(MARKETPLACE_CATEGORIES_BY_UUID_SELECTOR(categoryUUID))
  const theme = useCustomMuiTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const searchEnable = useEoValue(MARKETPLACE_SEARCH_BUTTON_STATE)
  const availableFilters = useEoValue<Filter[] | undefined>(MARKETPLACE_AVAILABLE_FILTERS_STATE)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          [theme.breakpoints.down('md')]: {
            gap: 0,
            flexDirection: 'column'
          }
        }}
      >
        <PageHeader title={category?.name}/>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            px: 'unset',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('md')]: {
              gap: 1,
              px: 2,
              justifyContent: 'center'
            }
          }}
        >
          <LocationsSearchField/>

          <LocationsFiltersResponsiveDialog
            activator={
              <FiltersButton
                disabled={!availableFilters?.length}
                small={isMobile && searchEnable}
              />
            }/>

          {(!viewType || viewType === 'list') && (
            <ViewButton
              small={isMobile && searchEnable}
              icon={<MapIcon/>}
              href={buildUrl(
                `${RoutesEnum.CATEGORY}/${categoryUUID}${RoutesEnum.LOCATIONS}`,
                new Map<Params, keyof typeof RoutesEnum | string>([
                  [ Params.VIEW_TYPE, 'map' ]
                ])
              )}
            >
              {t('global.map')}
            </ViewButton>
          )}
          {viewType === 'map' && (
            <ViewButton
              small={isMobile && searchEnable}
              icon={<ListIcon/>}
              href={buildUrl(
                `${RoutesEnum.CATEGORY}/${categoryUUID}${RoutesEnum.LOCATIONS}`,
                new Map<Params, keyof typeof RoutesEnum | string>([
                  [ Params.VIEW_TYPE, 'list' ]
                ])
              )}
            >
              {t('global.list')}
            </ViewButton>
          )}
        </Box>
      </Box>
      {isMobile && <MarketplaceSubHeader/>}
    </>
  )
}

export default LocationsHeader
