import StylesLocationsServiceInterface
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceInterface'
import StylesLocationsServiceStyleOne
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceStyleOne'
import StylesLocationsServiceStyleTwo
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesLocationsServiceStyleThree
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceStyleThree'

const getLocationsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocationsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocationsServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesLocationsServiceStyleThree(muiTheme)
    default:
      return new StylesLocationsServiceStyleOne(muiTheme)
  }
}

export default getLocationsTemplateService
