import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField } from '@mui/material'
import { ChangeEvent, SyntheticEvent } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import useFetchLocations from '~/src/hooks/marketplace/locations/useFetchLocations'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useDebounceCallable } from '~/src/hooks/useDebounce'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { MARKETPLACE_SEARCH_VALUE_STATE } from '~/src/stores/marketplace/filter'
import { MARKETPLACE_SEARCH_BUTTON_STATE } from '~/src/stores/marketplace/search-button'

const LocationsSearchField = () => {
  const { t } = useTranslations()
  const { fetch: fetchLocations } = useFetchLocations()
  const [ searchEnable, setSearchEnableState ] = useEoState(MARKETPLACE_SEARCH_BUTTON_STATE)
  const [ searchValue, setSearchValue ] = useEoState(MARKETPLACE_SEARCH_VALUE_STATE)
  const theme = useCustomMuiTheme()

  const _fetchLocationsDebounced = useDebounceCallable(() => {
    void fetchLocations()
  })

  const _handleOnFocus = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()

    setSearchEnableState(true)
  }

  const _handleOnBlur = () => {
    searchValue === '' && setSearchEnableState(false)
  }

  const _handleOnClear = () => {
    setSearchEnableState(false)
    setSearchValue('')
    void _fetchLocationsDebounced('')
  }

  const _handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    void _fetchLocationsDebounced(event.target.value)
  }

  return (
    <Button
      startIcon={<SearchIcon/>}
      onFocus={_handleOnFocus}
      onBlur={_handleOnBlur}
      disableRipple
      className={searchEnable ? 'open' : ''}
      sx={{
        p: '0.5em 1em',
        backgroundColor: 'container.main',
        color: 'container.contrastText',
        transition: 'var(--ease-in-out)',
        minWidth: '100px',
        height: '40px',
        '&.open': {
          width: '350px',
          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }
      }}
    >
      {!searchEnable && t('global.search')}
      {searchEnable && (
        <TextField
          autoFocus
          type={'search'}
          inputMode={'search'}
          variant='standard'
          fullWidth
          value={searchValue}
          onChange={_handleOnChange}
          InputProps={{
            endAdornment: (
              searchValue && (
                <IconButton
                  onClick={_handleOnClear}
                  disableRipple
                  size='small'
                >
                  <ClearOutlinedIcon/>
                </IconButton>
              )
            )
          }}
          sx={{
            mr: 2,
            [theme.breakpoints.down('md')]: {
              mr: 'unset'
            }
          }}
        />
      )}
    </Button>
  )
}

export default LocationsSearchField
