import TuneIcon from '@mui/icons-material/Tune'
import { buttonClasses } from '@mui/material'
import { useContext } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  small?: boolean,
  disabled: boolean,
}

const FiltersButton = ({ small, disabled = false }: Props) => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <Button
      disabled={disabled}
      small={small}
      startIcon={<TuneIcon/>}
      onClick={responsiveDialogContext.toggleOpen}
      sx={{
        p: '0.5em 1em',
        backgroundColor: 'container.main',
        color: 'container.contrastText',
        height: '40px',
        minWidth: small ? 'unset' : '100px',
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: 'inherit'
        }
      }}
    >
      {t('global.filters')}
    </Button>
  )
}

export default FiltersButton
