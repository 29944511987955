import LocationsHeader from '~/src/components/marketplace/locations/LocationsHeader'
import LocationsList from '~/src/components/marketplace/locations/LocationsList'
import LocationsMap from '~/src/components/marketplace/locations/map'
import useGetQueryParam from '~/src/hooks/router/useGetQueryParam'
import Params from '~/src/router/enums/params.enum'

interface Props {
  categoryUUID: string,
}

const LocationsContainer = ({ categoryUUID }: Props) => {
  const { params: viewType } = useGetQueryParam(Params.VIEW_TYPE)

  return (
    <>
      <LocationsHeader
        categoryUUID={categoryUUID}
        viewType={viewType}
      />

      {(!viewType || viewType === 'list') && (
        <LocationsList />
      )}
      {viewType === 'map' && (
        <LocationsMap />
      )}
    </>
  )
}

export default LocationsContainer
