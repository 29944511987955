import { Container, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

const LocationsEmpty = () => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()

  return (
    <Container
      sx={{
        width: '60%',
        [muiTheme.breakpoints.down('md')]: {
          width: '80%'
        },
        [muiTheme.breakpoints.down('sm')]: {
          width: '90%'
        }
      }}
    >
      <Title variant='h4'>
        {t('locations.empty.title')}
      </Title>
      <Typography sx={{ mt: 2 }}>
        {t('locations.empty.description')}
      </Typography>
    </Container>
  )
}

export default LocationsEmpty
