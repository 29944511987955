import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { MARKETPLACE_IS_FILTERING_SELECTOR, MARKETPLACE_SELECTED_FILTERS_STATE } from '~/src/stores/marketplace/filter'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'

const ClearFiltersButton = () => {
  const { t } = useTranslations()
  const setSelectedFiltersState = useSetEoState(MARKETPLACE_SELECTED_FILTERS_STATE)
  const isThereFilterSelected = useEoValue(MARKETPLACE_IS_FILTERING_SELECTOR)

  const handleClick = () => {
    setSelectedFiltersState([])
  }

  if (!isThereFilterSelected) {
    return null
  }

  return (
    <Button
      onClick={handleClick}
      color='inherit'
      sx={{
        textTransform: 'none',
        textDecoration: 'underline'
      }}
    >
      {t('filters.clear')}
    </Button>
  )
}

export default ClearFiltersButton
