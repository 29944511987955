import { FilterOption } from '@eo-storefronts/eo-core'
import cssUtils from '~/src/utils/CssUtils'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_IS_FILTER_SELECTED_SELECTOR } from '~/src/stores/marketplace/filter'
import Button from '~/src/components/mui-wrappers/buttons/Button'

interface Props {
  option: FilterOption,
  onClick(filter: FilterOption): void,
}

const FilterOptionButton = ({ option, onClick }: Props) => {
  const isFilterSelected = useEoValue(MARKETPLACE_IS_FILTER_SELECTED_SELECTOR(option))
  const theme = useCustomMuiTheme()

  const handleOnClick = () => {
    onClick(option)
  }

  return (
    <Button
      className={isFilterSelected ? 'selected' : ''}
      sx={{
        color: 'inherit',
        border: '1px solid',
        borderColor: 'inherit',
        backgroundColor: 'inherit',
        minWidth: '35px',
        '&.selected': {
          backgroundColor: cssUtils.addTransparencyToHexColor(theme.palette.primary.main, 25),
          color: 'primary.contrastText'
        }
      }}
      onClick={handleOnClick}>
      {option.name}
    </Button>
  )
}

export default FilterOptionButton
