import { Filter, FilterOption, Location } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect, useRef } from 'react'
import LocationsFilter from '~/src/components/marketplace/filter/LocationsFilter'
import useFetchLocations from '~/src/hooks/marketplace/locations/useFetchLocations'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import {
  MARKETPLACE_AVAILABLE_FILTERS_STATE,
  MARKETPLACE_FILTER_ACTION_STATE,
  MARKETPLACE_SELECTED_FILTERS_STATE
} from '~/src/stores/marketplace/filter'

interface Props {
  setFilteredLocations(locations: Location[]): void,
}

const LocationsFiltersContainer = ({ setFilteredLocations }: Props) => {
  const firstOpen = useRef<boolean>(true)
  const { data: locations, fetch: fetchLocations } = useFetchLocations()
  const availableFilters = useEoValue<Filter[] | undefined>(MARKETPLACE_AVAILABLE_FILTERS_STATE)
  const [ selectedFilters, setSelectedFiltersState ] = useEoState(MARKETPLACE_SELECTED_FILTERS_STATE)
  const setSelectedFilterActions = useSetEoState(MARKETPLACE_FILTER_ACTION_STATE)

  const _fetchFilteredLocations = () => {
    fetchLocations(
      {
        filters: selectedFilters,
        updateState: false,
        updateLoader: false
      }
    ).finally(() => {
      setSelectedFilterActions((state) => ({
        ...state,
        loading: false
      }))
    })
  }

  const handleOnSelectFilter = (option: FilterOption) => {
    let filterSelected = [ ...selectedFilters ]
    const optionSelectedIndex = selectedFilters.findIndex((stateOption: FilterOption) => stateOption.uuid === option.uuid)

    if (optionSelectedIndex === -1) {
      filterSelected = [
        ...selectedFilters,
        option
      ]
    } else {
      filterSelected.splice(optionSelectedIndex, 1)
    }

    setSelectedFiltersState(filterSelected)
  }

  useEffect(() => {
    if (firstOpen.current) {
      return
    }

    setSelectedFilterActions((state) => ({
      ...state,
      loading: true
    }))
    _fetchFilteredLocations()
  }, [ selectedFilters ])

  useEffect(() => {
    firstOpen.current = false
  }, [])

  useEffect(() => {
    setSelectedFilterActions((state) => ({
      ...state,
      value: locations?.length
    }))

    if (locations) {
      setFilteredLocations(locations)
    }
  }, [ locations ])

  if (!availableFilters?.length) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3
    }}>
      {availableFilters.map((filter: Filter, index: number) => (
        <LocationsFilter
          filter={filter}
          key={`filter-${filter.uuid}-${index}`}
          onClick={handleOnSelectFilter}
        />
      ))}
    </Box>
  )
}

export default LocationsFiltersContainer
