import { Location } from '@eo-storefronts/eo-core'
import { paperClasses } from '@mui/material'
import { ReactNode, useState } from 'react'
import LocationsFilterActions from '~/src/components/marketplace/filter/LocationsFilterActions'
import LocationsFiltersContainer from '~/src/components/marketplace/filter/LocationsFiltersContainer'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useSetEoState } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATIONS_STATE } from '~/src/stores/marketplace/locations'

interface Props {
  activator: ReactNode,
}

const LocationsFiltersResponsiveDialog = ({ activator }: Props) => {
  const [ filteredLocations, setFilteredLocations ] = useState<Location[]>([])
  const setLocationsState = useSetEoState(MARKETPLACE_LOCATIONS_STATE)

  const _handleOnSubmit = () => {
    setLocationsState(filteredLocations)
  }

  return (
    <ResponsiveDialog
      activator={activator}
      Title={<span>Filters</span>}
      Actions={<LocationsFilterActions onSubmit={_handleOnSubmit}/>}
      sx={{
        [`& .${paperClasses.root}`]: {
          minWidth: '40vw',
          backgroundColor: 'background.main',
          color: 'background.contrastText'
        }
      }}
    >
      <LocationsFiltersContainer setFilteredLocations={setFilteredLocations}/>
    </ResponsiveDialog>
  )
}

export default LocationsFiltersResponsiveDialog
