import getLocationsTemplateService
  from '~/src/pages/category/[slug]/locations/styles/GetLocationsTemplateService'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesLocationsServiceInterface
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocationsTemplate = (): StylesLocationsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locations')

  useLayout('locations')

  return getLocationsTemplateService(pageStyle, muiTheme)
}

export default useGetLocationsTemplate
