import { Box } from '@mui/material'
import ClearFiltersButton from '~/src/components/marketplace/ui/buttons/filters/ClearFiltersButton'
import ShowFiltersResultsButton from '~/src/components/marketplace/ui/buttons/filters/ShowFiltersResultsButton'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_IS_FILTERING_SELECTOR } from '~/src/stores/marketplace/filter'

interface Props {
  onSubmit(): void,
}

const LocationsFilterActions = ({ onSubmit }: Props) => {
  const isThereFilterSelected = useEoValue(MARKETPLACE_IS_FILTERING_SELECTOR)

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: isThereFilterSelected ? 'space-between' : 'flex-end',
      width: '100%'
    }}>
      <ClearFiltersButton/>
      <ShowFiltersResultsButton onClick={onSubmit} />
    </Box>
  )
}

export default LocationsFilterActions

