import { ReactNode } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Link from '~/src/components/mui-wrappers/Link'

interface Props {
  href: string,
  icon: ReactNode,
  children: ReactNode,
  small?: boolean,
}

const ViewButton = ({ children, href, icon, small }: Props) => {
  return (
    <Link
      href={href}
      removeBaseLinkStyle
    >
      <Button
        small={small}
        startIcon={icon}
        sx={{
          p: '0.5em 1em',
          backgroundColor: 'container.main',
          color: 'container.contrastText',
          transition: 'var(--ease-in-out)',
          width: small ? 'unset' : '100px',
          height: '40px',
          '&.open': {
            width: '350px'
          }
        }}
      >
        {children}
      </Button>
    </Link>
  )
}

export default ViewButton
