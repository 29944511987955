import { SxProps } from '@mui/material'
import StylesLocationsServiceInterface
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocationsServiceStyleThree extends TemplateServiceStyleBase implements StylesLocationsServiceInterface {
  public getPageSx(): SxProps {
    return {
      height: '100%',
      py: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        gap: 2,
        pt: 0,
        pb: 2
      }
    }
  }
}
