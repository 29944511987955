import { Box } from '@mui/material'
import { useEffect } from 'react'
import LocationsContainer from '~/src/components/marketplace/locations'
import useGetLocationsTemplate from '~/src/pages/category/[slug]/locations/styles/useGetLocationsTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import useFetchFilters from '~/src/hooks/marketplace/filters/useFetchFilters'
import useFetchLocations from '~/src/hooks/marketplace/locations/useFetchLocations'
import { useSetEoState } from '~/src/hooks/useEoState'
import {
  MARKETPLACE_AVAILABLE_FILTERS_STATE,
  MARKETPLACE_SEARCH_VALUE_STATE,
  MARKETPLACE_SELECTED_FILTERS_STATE
} from '~/src/stores/marketplace/filter'
import {
  MARKETPLACE_IS_FETCHING_LOCATIONS_STATE,
  MARKETPLACE_LOCATIONS_STATE
} from '~/src/stores/marketplace/locations'
import { MARKETPLACE_SEARCH_BUTTON_STATE } from '~/src/stores/marketplace/search-button'
import { useParams } from 'react-router-dom'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Locations = () => {
  const pageName: keyof typeof RoutesEnum = 'LOCATIONS'
  const { catId: categoryUUID } = useParams()
  const styles = useGetLocationsTemplate()
  const { fetch: fetchLocations } = useFetchLocations()
  const { fetch: fetchFilters } = useFetchFilters()
  const setSearchValue = useSetEoState(MARKETPLACE_SEARCH_VALUE_STATE)
  const setSearchButtonValue = useSetEoState(MARKETPLACE_SEARCH_BUTTON_STATE)
  const setSelectedFilters = useSetEoState(MARKETPLACE_SELECTED_FILTERS_STATE)
  const setLocations = useSetEoState(MARKETPLACE_LOCATIONS_STATE)
  const setIsFetchingLocations = useSetEoState(MARKETPLACE_IS_FETCHING_LOCATIONS_STATE)
  const setAvailableFilters = useSetEoState(MARKETPLACE_AVAILABLE_FILTERS_STATE)

  useEffect(() => {
    if (!categoryUUID) {
      return
    }

    setSelectedFilters([])
    setSearchValue('')
    setSearchButtonValue(false)
    setLocations([])
    setIsFetchingLocations(true)

    Promise.all([
      fetchLocations({ search: '', filters: [] }),
      fetchFilters()
    ]).then(([ , filters ]) => {
      setIsFetchingLocations(false)
      setAvailableFilters(filters)
    })
  }, [ categoryUUID ])

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Box sx={ styles.getPageSx() }>
        <LocationsContainer categoryUUID={ categoryUUID! }/>
      </Box>
    </SlideFromRight>
  )
}

export default Locations


