import { Box } from '@mui/material'
import LocationResponsiveDialogActions
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-actions'
import LocationResponsiveDialogContent
  from '~/src/components/marketplace/locations/location-responsive-dialog/location-responsive-dialog-content'

interface Props {
  isLoading: boolean,
}

const LocationMapPopoverContent = ({ isLoading }: Props) => {
  return (
    <Box
      className='location__card'
      sx={{
        borderRadius: '6px',
        width: 'inherit',
        '.location__card_content': {
          px: 0,
          '>.location__image_container': {
            position: 'unset',
            top: 'unset',
            img: {
              height: '20vh'
            }
          }
        }
      }}
    >
      <LocationResponsiveDialogContent isLoading={isLoading} />
      <LocationResponsiveDialogActions
        isLoading={isLoading}
        sx={{
          p: 1,
          gap: 1
        }}
      />
    </Box>
  )
}

export default LocationMapPopoverContent
