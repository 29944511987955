import { SxProps } from '@mui/material'
import StylesLocationsServiceInterface
  from '~/src/pages/category/[slug]/locations/styles/StylesLocationsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocationsServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocationsServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }
}
