import { Id, Location } from '@eo-storefronts/eo-core'
import { paperClasses } from '@mui/material'
import { MarkerProps } from '@react-google-maps/api'
import { useState } from 'react'
import LocationResponsiveDialog from '~/src/components/marketplace/locations/location-responsive-dialog'
import LocationMapMarker from '~/src/components/marketplace/locations/map/LocationMapMarker'
import LocationMapPopoverContent from '~/src/components/marketplace/locations/map/LocationMapPopoverContent'
import Popover from '~/src/components/mui-wrappers/styled/popover'
import useFetchLocation from '~/src/hooks/marketplace/locations/useFetchLocation'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { MARKETPLACE_LOCATION_SELECTED_STATE } from '~/src/stores/marketplace/locations'

interface Props extends MarkerProps {
  id?: Id,
}

const LocationMapMarkerContainer = ({ id, ...otherProps }: Props) => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const { loading: isLoading, fetch } = useFetchLocation()
  const setLocationSelected = useSetEoState(MARKETPLACE_LOCATION_SELECTED_STATE)
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const popoverId: string | undefined = open ? 'marker-popover' : undefined

  const _handleOnMapMarketClick = async (event: google.maps.MapMouseEvent) => {
    let anchor: HTMLElement = event.domEvent.target as HTMLElement

    if (!id || !anchor?.parentElement?.parentElement) {
      return
    }

    anchor = anchor.parentElement.parentElement
    setAnchorEl(anchor)

    const location: Location | undefined = await fetch(id)

    location && setLocationSelected(location)
  }

  const _handleOnPopoverClose = () => {
    setAnchorEl(null)
  }

  if (!id) {
    return null
  }

  if (isMobile) {
    return (
      <LocationResponsiveDialog
        id={id}
        activator={<LocationMapMarker {...otherProps} />}
      />
    )
  }

  return (
    <>
      <LocationMapMarker
        onClick={_handleOnMapMarketClick}
        {...otherProps} />

      <Popover
        open={open}
        id={popoverId}
        container={() => document.getElementById('locations__map__google_map')}
        onClose={_handleOnPopoverClose}
        sx={{
          position: 'absolute',
          bottom: 'unset',
          left: 'unset',
          width: '30vw',
          maxWidth: '350px',
          height: '100%',
          [`.${paperClasses.root}`]: {
            top: '0 !important',
            right: '0 important',
            inset: 'unset !important',
            minWidth: 'unset',
            minHeight: 'unset',
            maxWidth: '100%',
            maxHeight: '100%',
            transformOrigin: 'none !important'
          }
        }}
      >
        <LocationMapPopoverContent isLoading={isLoading} />
      </Popover>
    </>
  )
}

export default LocationMapMarkerContainer
