import { Filter, FilterOption } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import FilterOptionButton from '~/src/components/marketplace/ui/buttons/filters/FilterOptionButton'
import Title from '~/src/components/mui-wrappers/Title'

interface Props {
  filter: Filter,
  onClick(option: FilterOption): void,
}

const LocationsFilter = ({ filter, onClick }: Props) => (
  <Box>
    <Title
      variant='body1'
      gutterBottom
    >
      {filter.name}
    </Title>
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      gap: 2
    }}>
      {filter.options.map((filterOption: FilterOption, index: number) => (
        <FilterOptionButton
          option={filterOption}
          key={index}
          onClick={onClick}
        />
      ))}
    </Box>
  </Box>

)

export default LocationsFilter
