import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_LOCATIONS_STATE } from '~/src/stores/marketplace/locations'
import { MARKETPLACE_FILTER_ACTION_STATE } from '~/src/stores/marketplace/filter'

interface Props {
  onClick(): void,
}

const ShowFiltersResultsButton = ({ onClick }: Props) => {
  const { t } = useTranslations()
  const locations = useEoValue(MARKETPLACE_LOCATIONS_STATE)
  const filterActionState = useEoValue(MARKETPLACE_FILTER_ACTION_STATE)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnClick = () => {
    responsiveDialogContext?.toggleClose && responsiveDialogContext.toggleClose()
    onClick()
  }

  return (
    <Button
      onClick={handleOnClick}
      variant='outlined'
      color='primary'
      loading={filterActionState.loading}
    >
      {`${t('filters.display')} ${filterActionState.value ?? locations.length} ${t('filters.results').toLowerCase()}`}
    </Button>
  )
}

export default ShowFiltersResultsButton
